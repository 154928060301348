.invite-elt {
    display: flex;
    height: 50px;
    background-color: #333333;
    border-radius: 40px;
    padding: 5px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
}

.invite-elt > * {
    flex: 1 1 0;
    margin: auto;
}

.invite-elt > .thumbnail {
    width: 40px;
    display: inline-block;
    border-radius: 40px;
    margin-right: 5px;
    flex: 0 0 40px;
}

.invite-elt .label {
    /* float: right;
    margin-right: 10px; */
    font-size: 0.9em;
    text-transform: uppercase;
    color: gray;
}

.btn.btn-small {
    width: 100px;
    margin: 0 5px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

.actions {
    display: flex;
    margin: auto;
}

.btn.block {
    background-color: #B00020;
}

.btn.block:hover {
    background-color: #ca0025;
}

.warning-elt {
    border-radius: 30px;
    line-height: 30px;
    background-color: #ffa000;
    text-align: center;
    margin-bottom: 30px;
    padding-left:15px;
    padding-right:15px;
}

