.user-infos {
    display: flex;
    background:#4a9433;
    width: 100%;
    height: 60px;
    /* // border-bottom: 10px solid lighten($vert-zera, 3%); */
}


.user-infos-loading .user-infos-image {
    background-color: #3e822b;
    background-image: none;
}


.user-infos-loading .user-infos-name-welcome {
    width: 50%;
    height: 10pt;
    background-color: rgba(white, 0.2);
}

.user-infos-loading .user-infos-name-value {
    width: 80%;
    height: 10pt;
    margin-top: 8px;
    background-color: rgba(white, 0.5);
}

.user-infos-image {
    height: 45px;
    width: 45px;
    border-radius: 100%;
    border: 3px solid lighten(#4a9433, 5%);
    margin: auto 16px;

    background-color: darken(#4a9433, 10%);
    background-size: cover;
    background-repeat: no-repeat;
}

.user-infos-name {
    flex: 1;
    margin: auto 0;
    font-size: 13pt;
    color: white;
}

.user-infos-name-welcome {
    font-size: 10pt;
    opacity: 0.56;
}

.user-infos-logout {
    color: white;
    width: 45px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
}


.user-infos-logout:hover {
    cursor: pointer;
    background-color: lighten(#4a9433, 2%);
}
    /* &-image{
        height: 45px;
        width: 45px;
        border-radius: 100%;
        border: 3px solid lighten($vert-zera, 5%);
        margin: auto 16px;

        background-color: darken($vert-zera, 10%);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &-name{
        flex: 1;
        margin: auto 0;
        font-size: 13pt;
        color: white;

        &-welcome{
            font-size: 10pt;
            opacity: 0.56;
        }
    }

    &-logout{
        color: white;
        width: 45px;

        &:hover{
            cursor: pointer;
            background-color: lighten($vert-zera, 2%);
        }
    }
} */

@media screen and (max-width: 1024px) {

  .user-infos-name {
    display: none;
  }

  .user-infos-image {
    width: 30px!important;
    height: 30px!important;
    border-radius: 30px!important;
    margin: 15px 0 0 8px!important;
  }
  
}
