.list h2 {
  margin-top: 30px;
}
.list .teams {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.list .teams .team {
  width: 49%;
  height: 40px;
  background: #333333;
  border-radius: 40px;
  padding: 5px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
}
.list .teams .team .thumbnail {
  width: 30px;
  display: inline-block;
  border-radius: 30px;
  margin-right: 5px;
}
.list .teams .team p {
  display: inline-block;
  vertical-align: top;
  line-height: 32px;
}
.list .teams .team .pseudo {
  font-weight: 600;
  margin-right: 10px;
}
.list .teams .team .view-count {
  float: right;
  margin-right: 10px;
  font-size: 0.9em;
  text-transform: uppercase;
  color: gray;
}
.list .teams .team .view-count span {
  font-weight: 700;
  color: #fff;
  margin-left: 5px;
}
