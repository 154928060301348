@import url(https://use.typekit.net/lud0cfo.css);

.user-infos {
    display: -webkit-flex;
    display: flex;
    background:#4a9433;
    width: 100%;
    height: 60px;
    /* // border-bottom: 10px solid lighten($vert-zera, 3%); */
}


.user-infos-loading .user-infos-image {
    background-color: #3e822b;
    background-image: none;
}


.user-infos-loading .user-infos-name-welcome {
    width: 50%;
    height: 10pt;
    background-color: rgba(white, 0.2);
}

.user-infos-loading .user-infos-name-value {
    width: 80%;
    height: 10pt;
    margin-top: 8px;
    background-color: rgba(white, 0.5);
}

.user-infos-image {
    height: 45px;
    width: 45px;
    border-radius: 100%;
    border: 3px solid lighten(#4a9433, 5%);
    margin: auto 16px;

    background-color: darken(#4a9433, 10%);
    background-size: cover;
    background-repeat: no-repeat;
}

.user-infos-name {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: auto 0;
    font-size: 13pt;
    color: white;
}

.user-infos-name-welcome {
    font-size: 10pt;
    opacity: 0.56;
}

.user-infos-logout {
    color: white;
    width: 45px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
}


.user-infos-logout:hover {
    cursor: pointer;
    background-color: lighten(#4a9433, 2%);
}
    /* &-image{
        height: 45px;
        width: 45px;
        border-radius: 100%;
        border: 3px solid lighten($vert-zera, 5%);
        margin: auto 16px;

        background-color: darken($vert-zera, 10%);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &-name{
        flex: 1;
        margin: auto 0;
        font-size: 13pt;
        color: white;

        &-welcome{
            font-size: 10pt;
            opacity: 0.56;
        }
    }

    &-logout{
        color: white;
        width: 45px;

        &:hover{
            cursor: pointer;
            background-color: lighten($vert-zera, 2%);
        }
    }
} */

@media screen and (max-width: 1024px) {

  .user-infos-name {
    display: none;
  }

  .user-infos-image {
    width: 30px!important;
    height: 30px!important;
    border-radius: 30px!important;
    margin: 15px 0 0 8px!important;
  }
  
}

.home .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.home .logo-home {
  display: block;
  width: 300px;
  margin: 0 auto 10px auto;
}
.home h2, .home h3 {
  text-align: left;
}
.home h4 {
  margin-bottom: 20px;
}
.home .bloc {
  margin-bottom: 30px;
}
.home p {
  margin-bottom: 15px;
  color: #cccccc;
}
.home video {
  max-width: 100%;
}
.home ul {
  margin-left: 30px;
  margin-bottom: 20px;
}
.home ul li {
  margin-bottom: 5px;
  list-style: disc;
}

.image-grid {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 5px 0;
}

.image__cell {
  float: left;
  position: relative;
}

.image--basic {
  padding: 0 5px;
}

.basic__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.image__cell.is-collapsed .image--basic {
  cursor: pointer;
}

.image__cell.is-expanded .image--expand {
  max-height: 1300px;
  margin-bottom: 10px;
}

.image--expand {
  position: relative;
  left: -5px;
  padding: 0 5px;
  box-sizing: content-box;
  overflow: hidden;
  background: #222;
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
  transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
}
.image--expand h3 {
  margin: 20px 0 20px 20px;
}
.image--expand .contenu-jeu {
  padding: 0 20px;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
}
.image--expand .contenu-jeu img {
  width: 100%;
  margin-bottom: 20px;
}
.image--expand .contenu-jeu h4 {
  margin-bottom: 20px;
}
.image--expand .contenu-jeu .bloc:last-child {
  margin-bottom: 20px;
}
.image--expand .contenu-jeu li {
  color: #cccccc;
}

.image__cell.is-collapsed .arrow--up {
  height: 10px;
  width: 100%;
}

.image__cell.is-expanded .arrow--up {
  border-bottom: 8px solid #222;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  width: 0;
  margin: 2px auto 0;
}

.expand__close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #454545;
  font-size: 50px;
  line-height: 50px;
  text-decoration: none;
}

.expand__close:before {
  content: "\D7";
}

.expand__close:hover {
  color: #fff;
}

.image--large {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 530px) {
  .image__cell {
    width: 50%;
  }

  .image__cell:nth-of-type(2n+2) .image--expand {
    margin-left: -100%;
  }

  .image__cell:nth-of-type(2n+3) {
    clear: left;
  }

  .image--expand {
    width: 200%;
  }
}
@media only screen and (min-width: 531px) {
  .image__cell {
    width: 20%;
  }

  .image__cell:nth-of-type(5n+2) .image--expand {
    margin-left: -100%;
  }

  .image__cell:nth-of-type(5n+3) .image--expand {
    margin-left: -200%;
  }

  .image__cell:nth-of-type(5n+4) .image--expand {
    margin-left: -300%;
  }

  .image__cell:nth-of-type(5n+5) .image--expand {
    margin-left: -400%;
  }

  .image__cell:nth-of-type(5n+6) {
    clear: left;
  }

  .image--expand {
    width: 500%;
  }
}

.game-slider {
  width: 100%!important;
  margin-top: 200px;
}

.slick-dots {
  top: -150px!important;
  margin-left: 0!important;
  z-index: 999;
  height: 150px;
}

.slick-dots li {
  width: 130px!important;
  height: 130px!important;
  display: inline-block;
}

.slick-dots li button:before, .slick-dots li button {
  width: 100%!important;
  height: 100%!important;
  content: ''!important;
}

.slick-dots li:nth-child(1) {
  background-image: url(/static/media/minecraft.38c69004.png);
  background-size: cover;
}

.slick-dots li:nth-child(2) {
  background-image: url(/static/media/wow.53e79302.png);
  background-size: cover;
}

.slick-dots li:nth-child(3) {
  background-image: url(/static/media/trackmania.e18ba974.png);
  background-size: cover;
}

.slick-dots li:nth-child(4) {
  background-image: url(/static/media/h1z1.42928ea5.png);
  background-size: cover;
}

.slick-dots li:nth-child(5) {
  background-image: url(/static/media/dictee.63dd0f77.png);
  background-size: cover;
}

.slick-dots li:nth-child(6) {
  background-image: url(/static/media/rocket.1c368957.png);
  background-size: cover;
}

.slick-dots li:nth-child(7) {
  background-image: url(/static/media/starcraft.bb4f37eb.png);
  background-size: cover;
}

.slick-dots li:nth-child(8) {
  background-image: url(/static/media/worms.a8195eff.png);
  background-size: cover;
}

.slick-dots li:nth-child(9) {
  background-image: url(/static/media/portal.1cea86fa.png);
  background-size: cover;
}

.slick-dots li:nth-child(10) {
  background-image: url(/static/media/smash.282a6d1f.png);
  background-size: cover;
}

.slide {
  padding: 30px;
  background: #0D0D0D;
}

.slide .contenu-jeu {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
}
.slide .contenu-jeu img {
  width: 100%;
  margin-bottom: 20px;
}
.slide .contenu-jeu h4 {
  margin-bottom: 20px;
}
.slide .contenu-jeu .bloc:last-child {
  margin-bottom: 20px;
}
.slide .contenu-jeu li {
  color: #cccccc;
}

@media screen and (max-width: 1024px) {
  .slick-dots li {
    width: 40px!important;
    height: 40px!important;
    display: inline-block;
  }
}

.inscription {
  display: -webkit-flex;
  display: flex;
}
.inscription .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.inscription form {
  width: 400px;
  margin: 0 auto;
}
.inscription p {
  text-align: center;
  margin-bottom: 30px;
}

.list h2 {
  margin-top: 30px;
}
.list .teams {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.list .teams .team {
  width: 49%;
  height: 40px;
  background: #333333;
  border-radius: 40px;
  padding: 5px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
}
.list .teams .team .thumbnail {
  width: 30px;
  display: inline-block;
  border-radius: 30px;
  margin-right: 5px;
}
.list .teams .team p {
  display: inline-block;
  vertical-align: top;
  line-height: 32px;
}
.list .teams .team .pseudo {
  font-weight: 600;
  margin-right: 10px;
}
.list .teams .team .view-count {
  float: right;
  margin-right: 10px;
  font-size: 0.9em;
  text-transform: uppercase;
  color: gray;
}
.list .teams .team .view-count span {
  font-weight: 700;
  color: #fff;
  margin-left: 5px;
}

.invite-elt {
    display: -webkit-flex;
    display: flex;
    height: 50px;
    background-color: #333333;
    border-radius: 40px;
    padding: 5px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
}

.invite-elt > * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: auto;
}

.invite-elt > .thumbnail {
    width: 40px;
    display: inline-block;
    border-radius: 40px;
    margin-right: 5px;
    -webkit-flex: 0 0 40px;
            flex: 0 0 40px;
}

.invite-elt .label {
    /* float: right;
    margin-right: 10px; */
    font-size: 0.9em;
    text-transform: uppercase;
    color: gray;
}

.btn.btn-small {
    width: 100px;
    margin: 0 5px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

.actions {
    display: -webkit-flex;
    display: flex;
    margin: auto;
}

.btn.block {
    background-color: #B00020;
}

.btn.block:hover {
    background-color: #ca0025;
}

.warning-elt {
    border-radius: 30px;
    line-height: 30px;
    background-color: #ffa000;
    text-align: center;
    margin-bottom: 30px;
    padding-left:15px;
    padding-right:15px;
}


.inscr p {
  text-align: center;
  margin-bottom: 30px;
}
.inscr form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.inscr form .bloc {
  margin-bottom: 20px;
}
.inscr form input[type=submit] {
  margin-top: 40px;
}

.inscr .columns2 label {
  margin-bottom: 10px;
  display: block;
}

.bloc label a {
    text-decoration: underline;
}

.cgu {
  text-align: left!important;
}

.cgu p {
  margin-bottom: 20px;
}

.cgu h3 {
  text-align: left;
}

.cgu h4 {
  margin-bottom: 10px;
}

.cgu ul {
  margin-left: 30px;
  margin-bottom: 20px;
}
.cgu ul li {
  margin-bottom: 5px;
  list-style: disc;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

a {
  color: inherit;
}

body {
  background: #1a1a1a;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
}

h1 {
  font-size: 2em;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.6em;
  color: #00AA00;
  text-align: center;
  margin-bottom: 30px;
}

h3 {
  font-size: 1.3em;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
h3:after {
  content: "";
  display: block;
  width: 30px;
  background: #00AA00;
  height: 4px;
  margin-top: 5px;
}

h4 {
  font-size: 1em;
  color: #00AA00;
  text-transform: uppercase;
  font-weight: 700;
}

p {
  font-weight: 400;
  line-height: 1.2;
}

b {
  font-weight: 700;
}

.text_red {
  color: #da6262;
}

.text_blue {
  color: #4558e4;
}

.text_green {
  color: #50ca56;
}

.text_pink {
  color: #f368a9;
}

.text_yellow {
  color: #f1b432;
}

.container {
  width: calc( 100% - 300px );
  margin-left: 300px;
  padding: 30px;
  min-height: 100vh;
}

.content {
  width: 100%;
  background: #262626;
  padding: 40px;
}

.bloc {
  width: 100%;
}

.columns2 {
  width: 45%;
}

.btn {
  width: 250px;
  height: 50px;
  background: #00AA00;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.btn:hover {
  background: #00c400;
  letter-spacing: 1px;
}

input[type=text] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=email] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=number] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=date] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=submit] {
  width: 100%;
  height: 50px;
  background: #00AA00;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}
input[type=submit]:hover {
  background: #00c400;
}

.checkbox {
  margin-bottom: 10px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #000;
}
.styled-checkbox:hover + label:before {
  background: #00AA00;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px #fff;
}
.styled-checkbox:checked + label:before {
  background: #00AA00;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #000;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #000;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000, 4px -6px 0 #000, 4px -8px 0 #000;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

select {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px !important;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 0px !important;
}

.side-menu-left {
  width: 300px;
  height: 100%;
  background: #0d0d0d;
  position: fixed;
  left: 0;
  top: 0;
}
.side-menu-left .logo {
  width: 200px;
  margin: 50px 0 50px 50px;
}
.side-menu-left .logo a {
  width: 100%;
  display: block;
}
.side-menu-left .logo img {
  width: 100%;
}
.side-menu-left .twitch-login {
  display: block;
  width: 230px;
  height: 50px;
  background: #6441A4;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0 auto 50px auto;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.side-menu-left .twitch-login i {
  margin-right: 5px;
}
.side-menu-left .twitch-login:hover {
  background: #7d5abe;
}
.side-menu-left .side-nav ul li {
  text-align: center;
  width: 250px;
  height: 50px;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 50px;
  border-bottom: 2px solid #0d0d0d;
}
.side-menu-left .side-nav ul li i {
  margin-right: 5px;
  color: #00AA00;
}
.side-menu-left .side-nav ul li a {
  display: block;
  width: 100%;
  height: 100%;
}
.side-menu-left .side-nav ul li:hover, .side-menu-left .side-nav ul li.active {
  background: #1a1a1a;
  border-bottom: 2px solid #00AA00;
}

.cgu {
  text-align: left;
}

/* ERROR */


.error-msg {
  display: inline-block;
  padding: 5px 10px;
  background: #da6262;
  color: #fff;
  margin: 5px 0;
  border-radius: 5px;
  text-shadow: 0 3px 7px rgba(0,0,0,.5);
}

.snackbar-msg {
  position: fixed;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline-block;
  padding: 20px 25px;
  z-index: 999;
  background: #000;
  box-shadow: 0 10px 20px rgba(0,0,0,.5);
  -webkit-animation-name: snackbar-enter;
          animation-name: snackbar-enter;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay:0s;
          animation-delay:0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes snackbar-enter {
  0%{ bottom: -100px; }
  100%{ bottom: 20px; }
}

@keyframes snackbar-enter {
  0%{ bottom: -100px; }
  100%{ bottom: 20px; }
}

@-webkit-keyframes snackbar-out {
  0%{ bottom: 20px; }
  100%{ bottom: -100px; }
}

@keyframes snackbar-out {
  0%{ bottom: 20px; }
  100%{ bottom: -100px; }
}


@media screen and (max-width: 1024px) {
  .container {
    width: calc( 100% - 60px );
    margin-left: 60px;
    padding: 10px;
  }

  .btn {
    width: 200px;
  }

  .side-menu-left {
    width: 60px;
  }
  .side-menu-left .logo {
    display: none;
  }
  .side-menu-left .twitch-login {
    width: 45px;
    height: 45px;
    margin: 10px auto 10px auto;
  }
  .side-menu-left .twitch-login span {
    display: none;
  }
  .side-menu-left .twitch-login i {
    margin-right: 0;
    font-size: 1.2em;
  }
  .side-menu-left .side-nav ul li {
    width: 45px;
  }
  .side-menu-left .side-nav ul li span {
    display: none;
  }
  .side-menu-left .side-nav ul li i {
    margin-right: 0;
    font-size: 1.2em;
  }

  .home .logo-home {
    max-width: 200px;
  }

  .list .teams .team {
    width: 100%;
    height: auto;
    border-radius: 20px;
    text-align: center;
  }
  .list .teams .team .pseudo {
    font-size: 0.8em;
    width: calc( 100% - 60px );
  }
  .list .teams .team .view-count {
    font-size: 0.8em;
    float: none;
  }

  .inscription form {
    width: 100%;
  }

  .columns2 {
    width: 100%;
  }
}

