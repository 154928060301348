@import "https://use.typekit.net/lud0cfo.css";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

a {
  color: inherit;
}

body {
  background: #1a1a1a;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
}

h1 {
  font-size: 2em;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.6em;
  color: #00AA00;
  text-align: center;
  margin-bottom: 30px;
}

h3 {
  font-size: 1.3em;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
h3:after {
  content: "";
  display: block;
  width: 30px;
  background: #00AA00;
  height: 4px;
  margin-top: 5px;
}

h4 {
  font-size: 1em;
  color: #00AA00;
  text-transform: uppercase;
  font-weight: 700;
}

p {
  font-weight: 400;
  line-height: 1.2;
}

b {
  font-weight: 700;
}

.text_red {
  color: #da6262;
}

.text_blue {
  color: #4558e4;
}

.text_green {
  color: #50ca56;
}

.text_pink {
  color: #f368a9;
}

.text_yellow {
  color: #f1b432;
}

.container {
  width: calc( 100% - 300px );
  margin-left: 300px;
  padding: 30px;
  min-height: 100vh;
}

.content {
  width: 100%;
  background: #262626;
  padding: 40px;
}

.bloc {
  width: 100%;
}

.columns2 {
  width: 45%;
}

.btn {
  width: 250px;
  height: 50px;
  background: #00AA00;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  transition: 0.4s;
}
.btn:hover {
  background: #00c400;
  letter-spacing: 1px;
}

input[type=text] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=email] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=number] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=date] {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

input[type=submit] {
  width: 100%;
  height: 50px;
  background: #00AA00;
  border: none;
  outline: none;
  padding: 0 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  transition: 0.4s;
  cursor: pointer;
}
input[type=submit]:hover {
  background: #00c400;
}

.checkbox {
  margin-bottom: 10px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #000;
}
.styled-checkbox:hover + label:before {
  background: #00AA00;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px #fff;
}
.styled-checkbox:checked + label:before {
  background: #00AA00;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #000;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #000;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000, 4px -6px 0 #000, 4px -8px 0 #000;
  transform: rotate(45deg);
}

select {
  width: 100%;
  height: 50px;
  background: #1a1a1a;
  border: none;
  outline: none;
  padding: 0 20px !important;
  font-family: proxima-nova, sans-serif;
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 0px !important;
}

.side-menu-left {
  width: 300px;
  height: 100%;
  background: #0d0d0d;
  position: fixed;
  left: 0;
  top: 0;
}
.side-menu-left .logo {
  width: 200px;
  margin: 50px 0 50px 50px;
}
.side-menu-left .logo a {
  width: 100%;
  display: block;
}
.side-menu-left .logo img {
  width: 100%;
}
.side-menu-left .twitch-login {
  display: block;
  width: 230px;
  height: 50px;
  background: #6441A4;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0 auto 50px auto;
  transition: 0.4s;
}
.side-menu-left .twitch-login i {
  margin-right: 5px;
}
.side-menu-left .twitch-login:hover {
  background: #7d5abe;
}
.side-menu-left .side-nav ul li {
  text-align: center;
  width: 250px;
  height: 50px;
  margin: 0 auto;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  transition: 0.4s;
  line-height: 50px;
  border-bottom: 2px solid #0d0d0d;
}
.side-menu-left .side-nav ul li i {
  margin-right: 5px;
  color: #00AA00;
}
.side-menu-left .side-nav ul li a {
  display: block;
  width: 100%;
  height: 100%;
}
.side-menu-left .side-nav ul li:hover, .side-menu-left .side-nav ul li.active {
  background: #1a1a1a;
  border-bottom: 2px solid #00AA00;
}

.cgu {
  text-align: left;
}

/* ERROR */


.error-msg {
  display: inline-block;
  padding: 5px 10px;
  background: #da6262;
  color: #fff;
  margin: 5px 0;
  border-radius: 5px;
  text-shadow: 0 3px 7px rgba(0,0,0,.5);
}

.snackbar-msg {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  padding: 20px 25px;
  z-index: 999;
  background: #000;
  box-shadow: 0 10px 20px rgba(0,0,0,.5);
  animation-name: snackbar-enter;
  animation-duration: .7s;
  animation-direction: forwards;
  animation-timing-function: ease-in-out;
  animation-delay:0s;
  animation-fill-mode: forwards;
}

@keyframes snackbar-enter {
  0%{ bottom: -100px; }
  100%{ bottom: 20px; }
}

@keyframes snackbar-out {
  0%{ bottom: 20px; }
  100%{ bottom: -100px; }
}


@media screen and (max-width: 1024px) {
  .container {
    width: calc( 100% - 60px );
    margin-left: 60px;
    padding: 10px;
  }

  .btn {
    width: 200px;
  }

  .side-menu-left {
    width: 60px;
  }
  .side-menu-left .logo {
    display: none;
  }
  .side-menu-left .twitch-login {
    width: 45px;
    height: 45px;
    margin: 10px auto 10px auto;
  }
  .side-menu-left .twitch-login span {
    display: none;
  }
  .side-menu-left .twitch-login i {
    margin-right: 0;
    font-size: 1.2em;
  }
  .side-menu-left .side-nav ul li {
    width: 45px;
  }
  .side-menu-left .side-nav ul li span {
    display: none;
  }
  .side-menu-left .side-nav ul li i {
    margin-right: 0;
    font-size: 1.2em;
  }

  .home .logo-home {
    max-width: 200px;
  }

  .list .teams .team {
    width: 100%;
    height: auto;
    border-radius: 20px;
    text-align: center;
  }
  .list .teams .team .pseudo {
    font-size: 0.8em;
    width: calc( 100% - 60px );
  }
  .list .teams .team .view-count {
    font-size: 0.8em;
    float: none;
  }

  .inscription form {
    width: 100%;
  }

  .columns2 {
    width: 100%;
  }
}
