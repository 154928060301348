.inscription {
  display: flex;
}
.inscription .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inscription form {
  width: 400px;
  margin: 0 auto;
}
.inscription p {
  text-align: center;
  margin-bottom: 30px;
}
