.cgu {
  text-align: left!important;
}

.cgu p {
  margin-bottom: 20px;
}

.cgu h3 {
  text-align: left;
}

.cgu h4 {
  margin-bottom: 10px;
}

.cgu ul {
  margin-left: 30px;
  margin-bottom: 20px;
}
.cgu ul li {
  margin-bottom: 5px;
  list-style: disc;
}
