.home .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.home .logo-home {
  display: block;
  width: 300px;
  margin: 0 auto 10px auto;
}
.home h2, .home h3 {
  text-align: left;
}
.home h4 {
  margin-bottom: 20px;
}
.home .bloc {
  margin-bottom: 30px;
}
.home p {
  margin-bottom: 15px;
  color: #cccccc;
}
.home video {
  max-width: 100%;
}
.home ul {
  margin-left: 30px;
  margin-bottom: 20px;
}
.home ul li {
  margin-bottom: 5px;
  list-style: disc;
}

.image-grid {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 5px 0;
}

.image__cell {
  float: left;
  position: relative;
}

.image--basic {
  padding: 0 5px;
}

.basic__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.image__cell.is-collapsed .image--basic {
  cursor: pointer;
}

.image__cell.is-expanded .image--expand {
  max-height: 1300px;
  margin-bottom: 10px;
}

.image--expand {
  position: relative;
  left: -5px;
  padding: 0 5px;
  box-sizing: content-box;
  overflow: hidden;
  background: #222;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
}
.image--expand h3 {
  margin: 20px 0 20px 20px;
}
.image--expand .contenu-jeu {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.image--expand .contenu-jeu img {
  width: 100%;
  margin-bottom: 20px;
}
.image--expand .contenu-jeu h4 {
  margin-bottom: 20px;
}
.image--expand .contenu-jeu .bloc:last-child {
  margin-bottom: 20px;
}
.image--expand .contenu-jeu li {
  color: #cccccc;
}

.image__cell.is-collapsed .arrow--up {
  height: 10px;
  width: 100%;
}

.image__cell.is-expanded .arrow--up {
  border-bottom: 8px solid #222;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  width: 0;
  margin: 2px auto 0;
}

.expand__close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #454545;
  font-size: 50px;
  line-height: 50px;
  text-decoration: none;
}

.expand__close:before {
  content: "×";
}

.expand__close:hover {
  color: #fff;
}

.image--large {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 530px) {
  .image__cell {
    width: 50%;
  }

  .image__cell:nth-of-type(2n+2) .image--expand {
    margin-left: -100%;
  }

  .image__cell:nth-of-type(2n+3) {
    clear: left;
  }

  .image--expand {
    width: 200%;
  }
}
@media only screen and (min-width: 531px) {
  .image__cell {
    width: 20%;
  }

  .image__cell:nth-of-type(5n+2) .image--expand {
    margin-left: -100%;
  }

  .image__cell:nth-of-type(5n+3) .image--expand {
    margin-left: -200%;
  }

  .image__cell:nth-of-type(5n+4) .image--expand {
    margin-left: -300%;
  }

  .image__cell:nth-of-type(5n+5) .image--expand {
    margin-left: -400%;
  }

  .image__cell:nth-of-type(5n+6) {
    clear: left;
  }

  .image--expand {
    width: 500%;
  }
}

.game-slider {
  width: 100%!important;
  margin-top: 200px;
}

.slick-dots {
  top: -150px!important;
  margin-left: 0!important;
  z-index: 999;
  height: 150px;
}

.slick-dots li {
  width: 130px!important;
  height: 130px!important;
  display: inline-block;
}

.slick-dots li button:before, .slick-dots li button {
  width: 100%!important;
  height: 100%!important;
  content: ''!important;
}

.slick-dots li:nth-child(1) {
  background-image: url("./assets/images/jeux/minecraft.png");
  background-size: cover;
}

.slick-dots li:nth-child(2) {
  background-image: url("./assets/images/jeux/wow.png");
  background-size: cover;
}

.slick-dots li:nth-child(3) {
  background-image: url("./assets/images/jeux/trackmania.png");
  background-size: cover;
}

.slick-dots li:nth-child(4) {
  background-image: url("./assets/images/jeux/h1z1.png");
  background-size: cover;
}

.slick-dots li:nth-child(5) {
  background-image: url("./assets/images/jeux/dictee.png");
  background-size: cover;
}

.slick-dots li:nth-child(6) {
  background-image: url("./assets/images/jeux/rocket.png");
  background-size: cover;
}

.slick-dots li:nth-child(7) {
  background-image: url("./assets/images/jeux/starcraft.png");
  background-size: cover;
}

.slick-dots li:nth-child(8) {
  background-image: url("./assets/images/jeux/worms.png");
  background-size: cover;
}

.slick-dots li:nth-child(9) {
  background-image: url("./assets/images/jeux/portal.png");
  background-size: cover;
}

.slick-dots li:nth-child(10) {
  background-image: url("./assets/images/jeux/smash.png");
  background-size: cover;
}

.slide {
  padding: 30px;
  background: #0D0D0D;
}

.slide .contenu-jeu {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.slide .contenu-jeu img {
  width: 100%;
  margin-bottom: 20px;
}
.slide .contenu-jeu h4 {
  margin-bottom: 20px;
}
.slide .contenu-jeu .bloc:last-child {
  margin-bottom: 20px;
}
.slide .contenu-jeu li {
  color: #cccccc;
}

@media screen and (max-width: 1024px) {
  .slick-dots li {
    width: 40px!important;
    height: 40px!important;
    display: inline-block;
  }
}
