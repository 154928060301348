.inscr p {
  text-align: center;
  margin-bottom: 30px;
}
.inscr form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.inscr form .bloc {
  margin-bottom: 20px;
}
.inscr form input[type=submit] {
  margin-top: 40px;
}

.inscr .columns2 label {
  margin-bottom: 10px;
  display: block;
}

.bloc label a {
    text-decoration: underline;
}
